<template>
  <div class="efficiency-all">
    <template v-if="loadProject.length!=0">
      <el-card class="efficiency box"
        v-for="(item,index) in loadProject"
        :key="item.id+'a'">
        <div slot="header"
          class="clearfix">
          <span class="project-name">{{item.name}}</span>
          <div class="efficiency-content-all">
            <div class="flex-row-space-between">
              <div></div>
              <el-dropdown style="margin:10px 10px;"
                trigger="click"
                @command="getData">
                <el-button type="primary"
                  class="btn-wrapper"
                  style="width:100px!important">
                  最近 {{dateActive}}天
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <template v-for="(day) in [7,15,30]">
                    <el-dropdown-item el-dropdown-item
                      :key="index+day+'a'+item.id"
                      style="padding:5px 25px;"
                      :command="{day:day,project_id:item.id,user:tempUserList[index],index:index}">最近{{day}}天
                    </el-dropdown-item>
                  </template>

                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="efficiency-content flex-row-space-between">
              <div class="efficiency-content-img-content flexcolumn-row-center">
                <div class="img-portrait">
                  <div :id='index'
                    class="img-por"></div>
                </div>

                <div class="portrait-name">
                  <span class="text-overflow " style="display:inline-block;max-width:150px;vertical-align:bottom">{{tempUserList[index].real_name}}</span>
                  <span class="ml10">{{tempUserList[index].position}}</span>
                </div>
                <div class="portrait-probability">
                  通过率
                  <span class="probability">{{(tempUserList[index].uploadImg==0?0:tempUserList[index].checkImg/tempUserList[index].uploadImg*100).toFixed(2)+"%"}}</span>
                  <!-- <span class="probability">{{tempUserList[index]}}</span> -->
                </div>
                <div>
                  <div class="number-audits flex-row"><span class="yuandian"
                      :style="{'background':tempUserList[index].color}"></span><span>通过审核文件数</span></div>
                  <div class="total-uploads flex-row"><span class="yuandian yuandian1"></span><span>上传文件总数</span></div>
                </div>
              </div>
              <div class="efficiency-content-echarts flexcolumn-row-center">
                <div :id='item.id'
                  class="efficiency-charts"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="efficiency-statistics">该项目中所有人员耗时统计</div>
        <div class="proportion flex-row">
          <div v-for="(user,idxU) in item.users"
            :key="'b'+user.user_id+idxU"
            class="proportion-item"
            @click="getUserData(user,index,item.id)"
            :style="{'background':user.color,'width':user.width}"></div>
        </div>
        <div class="proportion-nickName flex-row">
          <div class="proportion-nickName-item flex-row"
            v-for="(user,idxU2) in item.users"
            :key="'d'+user.id+idxU2+item.id">
            <span @click="getUserData(user,index,item.id)"
              :style="{'background':user.color}"
              class="nickName-color1"></span> <span @click="getUserData(user,index,item.id)"
              class="nickName-name">{{user.real_name}}</span>
          </div>
        </div>
      </el-card>
    </template>
    <div v-else
      class="null-catalog-all flex-column-all-center">
      <img style="widht:56px;height:56px;"
        src="../../../src/assets/imgs/null.png" />
      <div class="null-catalog">暂无数据</div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import request from "@request/api/index";
import { deepClone } from "@/utils/utils";
import memberEfficiencyMix from './components/mixins/memberEfficiencyMix';
export default {
  name:'efficiency',
  components: {},
  mixins:[memberEfficiencyMix],
  data() {
    return {
      enterpriseData: [],
      oExamineData: [],
      oExamineProject: [],
      dateActive: 15,
      dateValue: [],
      stat_time: "",
      oExamineProjecter: {},
      paramsData: {},
      oExamineProjecterUser: {},
      tempUserList: [],
      uploadshow: false,
      //定义队列存放已加载的数据
      loadProject: [],
      loadCount: 0,
      loadStartCount: 0,
      loadSize: 3,
      isLoading: false,
    };
  },
  created() {},
  mounted() {
     this.$nextTick(() => {
      const el = document.querySelector('.ivu-layout-content');
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        const scrollTop = el.scrollTop;
        const scrollHeight = el.scrollHeight;
        if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
          this.scrollLoad();
          console.log('2222');
        }
      };
    });
  },
  beforeMount() {
    this.EnterprisenickName();
  },
  methods: {
    async getUserData(user, index, project_id) {
      this.paramsData.user_id = user.user_id;
      let UserDataChart = await this.getrequesToExamine(
        project_id,
        user.user_id,
        this.dateActive
      );
      //更改头像的tempUser
      this.tempUserList[index] = user;
      let i = index;
      this.tempUserList[i].lineChart = UserDataChart.list;
      this.tempUserList[i].uploadImg = UserDataChart.upload;
      this.tempUserList[i].checkImg = UserDataChart.check;
      this.drawLine();
      this.$forceUpdate();
    },
    setSearchDate(n) {
      this.dateActive = n;
    },
    async getData(n) {
      this.dateActive = n.day;
      // console.log('this.loadProject', this.loadProject)
      await this.loadProject.forEach(async (project, index) => {
        //将原来的(n.project_id, n.user.user_id, n.day)改为
        let chars = await this.getrequesToExamine(
          project.id,
          this.tempUserList[index].user_id,
          n.day
        );
        // console.log('echars', chars)
        let i = index;
        this.tempUserList[i].lineChart = chars.list;
        this.tempUserList[i].uploadImg = chars.upload;
        this.tempUserList[i].checkImg = chars.check;
      });

      let timeoutId = window.setTimeout(() => {
        console.log("this.tempUserList", this.tempUserList);
        this.drawLine();
      }, 1155);
    },
    async EnterprisenickName() {
      this.enterpriseData = await request.enterprise.requestEnterprise({});
      this.enterpriseData = this.enterpriseData.data.data;
      // console.log('this.enterpriseData ', this.enterpriseData)
      // console.log("this.enterksdjflksjdf", this.enterpriseData)
      this.paramsData = {};

      //debugger;
      let enterpriseDataSize = this.enterpriseData.length;
      let size =
        enterpriseDataSize <= this.loadSize
          ? enterpriseDataSize
          : this.loadSize;
      //如果加载数据大于等于每次加载数量后就停止加载
      //将loadProject 赋值3个元素
      while (size > this.loadCount) {
        // this.oExamineProject = item.users
        // this.oExamineProject.forEach(i => {
        //   this.oExamineProjecter = i
        //   this.paramsData = deepClone(this.oExamineProjecter)
        // })
        //debugger;
        let item = this.enterpriseData[0];
        let index = this.loadCount;

        //tempUserList赋初始值
        this.tempUserList.push(item.users[0]);
        //获取每一个item的users，计算每一个users的权重
        //1.计算当前项目共审核通过多少个
        var count = 0;
        var uploadAll = 0;
        item.users.forEach((user) => {
          uploadAll += user.upload;
          count += user.time;
        });
        if (uploadAll == 0) {
          this.uploadshow = false;
        }
        //debugger;
        item.users.forEach((user) => {
          if (count == 0) {
            user.width = user.width = (1 / item.users.length) * 100 + "%";
          } else {
            // user.width = user.time / count * 100 + "%";
            user.width = (user.time / count) * 100 + "%";
          }
        });

        //获取当前项目第一个人的折线图
        var dataEchar = await this.getrequesToExamine(
          item.id,
          item.users[0].user_id,
          this.dateActive
        );
        // console.log("打印数据", dataEchar)
        //将当前项目存入 选中人 折线图a
        this.tempUserList[index].lineChart = dataEchar.list;
        this.tempUserList[index].uploadImg = dataEchar.upload;
        this.tempUserList[index].checkImg = dataEchar.check;
        this.loadProject.push(this.enterpriseData[0]);
        this.enterpriseData.splice(0, 1);

        this.loadCount++;
        this.loadStartCount++;
      }
      this.$nextTick(async function () {
        //延迟两秒执行
        let timeoutId = window.setTimeout(() => {
          this.drawLine();
        }, 50);
      });
    },
    async getrequesToExamine(project_id, user_id, dateActive) {
      // console.log('this.paramsData',this.paramsData)
      let params = {
        project_id: project_id,
        user_id: user_id,
        day: dateActive,
      };
      let echarsData = await request.enterprise.requesToExamine(params);
      this.toExamine = echarsData.data.data;
      // console.log('toExamine', this.toExamine)
      //将查询到的结果返回
      return echarsData.data.data;
    },
    async scrollLoad() {
      //document的滚动高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //可视区高度
      let nowScotop =
        document.documentElement.clientHeight || document.body.clientHeight;
      //已滚动高度
      let wheight =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("skfjlskjfkls", scrollHeight, nowScotop, wheight)
      if (nowScotop > scrollHeight - wheight - 5) {
        // console.log("触发懒加载", this.isLoading)
        if (this.isLoading) {
          //是true就结束
          return;
        } else {
          //是false则加载
          //1. 进入这里后立马将isLoading改为true，避免重复进入
          this.isLoading = true;
          //2. 执行处理方法

          if (this.enterpriseData.length == 0) {
            return;
          }
          //往 loadProject中添加数据
          let enterpriseDataSize = this.enterpriseData.length;
          let size =
            enterpriseDataSize <= this.loadSize
              ? enterpriseDataSize
              : this.loadSize;
          //如果加载数据大于等于每次加载数量后就停止加载
          //将loadProject 赋值3个元素
          //从上次加载的结尾下标添加元素
          //debugger;
          let loadProjectSize = this.loadProject.length;
          while (loadProjectSize + size > this.loadCount) {
            let i = this.loadCount++;

            let item = this.enterpriseData[0];
            this.tempUserList.push(item.users[0]);
            //获取每一个item的users，计算每一个users的权重
            //1.计算当前项目共审核通过多少个
            var count = 0;
            var uploadAll = 0;
            item.users.forEach((user) => {
              uploadAll += user.upload;
              count += user.time;
            });
            if (uploadAll == 0) {
              this.uploadshow = false;
            }
            //debugger;
            item.users.forEach((user) => {
              if (count == 0) {
                user.width = (1 / item.users.length) * 100 + "%";
              } else {
                // user.width = user.time / count * 100 + "%";
                user.width = (user.time / count) * 100 + "%";
              }
            });
            //获取当前项目第一个人的折线图
            var dataEchar = await this.getrequesToExamine(
              item.id,
              item.users[0].user_id,
              this.dateActive
            );
            // console.log("打印数据", dataEchar)
            //将当前项目存入 选中人 折线图a
            this.tempUserList[i].lineChart = dataEchar.list;
            this.tempUserList[i].uploadImg = dataEchar.upload;
            this.tempUserList[i].checkImg = dataEchar.check;
            // console.log('dataEchar', this.tempUserList[i].uploadImg)
            // console.log('checkImg', this.tempUserList[i].checkImg)

            //将数据放入loadProject中
            this.loadProject.push(this.enterpriseData[0]);
            //将数据从enter中移除
            this.enterpriseData.splice(0, 1);
          }
          this.$nextTick(async function () {
            //延迟两秒执行
            let timeoutId = window.setTimeout(() => {
              this.drawLine();
            }, 200);
          });
          //3.执行完毕将isloading改为false
          this.isLoading = false;
        }
      }
    },
  },
  destroyed(){

  }
};
</script>
<style lang="scss" scoped>
.efficiency-all {
  //height: 100%;
}
.efficiency {
  margin-top: 24px;
  height: 530px;
  .efficiency-content-all {
    width: 100%;
    height: 320px;
    border-top: 1px solid #e9e9e9;
    // border-bottom:1px solid #E9E9E9;
  }
  .efficiency-content {
    width: 100%;
    height: 291px;
    // margin-top: 58px;
    margin-bottom: 20px;
  }
  .efficiency-content-img-content {
    width: 15%;
    height: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #25233a;
    line-height: 20px;
    // background: red;
    .img-portrait {
      width: 115px;
      height: 115px;
      border-radius: 50%;
      .img-por {
        height: 100%;
        width: 100%;
      }
      // background-color: #3468fe;
    }
    .portrait-name {
      height: 22px;
      font-size: 16px;
      font-family: HelveticaNeue;
      color: #33343e;
      line-height: 18px;
      margin-top: 10px;
    }
    .portrait-probability {
      height: 25px;
      font-size: 16px;
      margin-top: 5px;
      line-height: 20px;
      .probability {
        margin-left: 4px;
        font-size: 22px;
        font-weight: 700;
      }
    }
    .number-audits {
      margin-top: 31px;
      text-align: left;
    }
    .yuandian {
      width: 8px;
      height: 8px;
      margin-right: 3px;
      border-radius: 50%;
      // background: #3468fe;
    }
    .yuandian1 {
      background: #62cfe3;
    }
    .total-uploads {
      margin-top: 12px;
      text-align: left;
    }
  }
  .efficiency-content-echarts {
    width: 85%;
    height: 100%;
  }
}
.efficiency-charts {
  width: 100%;
  height: 100%;
}
.efficiency-statistics {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3468fe;
  line-height: 20px;
}
.proportion {
  height: 24px;
  width: 100%;
  margin-top: 16px;
  // background-color: red;
  .proportion-item {
    width: 30%;
    height: 20px;
    // margin-left: 10px;
    // background-color: green;
  }
  .proportion-item1 {
    width: 30%;
    height: 100%;
    background-color: greenyellow;
  }
  .proportion-item2 {
    width: 30%;
    height: 100%;
    background-color: indianred;
  }
}
.proportion-nickName {
  margin-top: 16px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .proportion-nickName-item {
    margin-right: 24px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #25233a;
    line-height: 20px;
  }
  .proportion-nickName-item1 {
    margin-right: 24px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #25233a;
    line-height: 20px;
  }
  .proportion-nickName-item2 {
    margin-right: 24px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #25233a;
    line-height: 20px;
  }
}
.nickName-color1 {
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
}
.nickName-name {
  //  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;
}
.project-name {
  display: inline-block;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3468fe;
  line-height: 20px;
  margin-bottom: 10px;
}
.null-catalog-all {
  height: 100%;
  .null-catalog {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b3b9c7;
    line-height: 20px;
    margin-top: 20px;
  }
}
</style>
