export const getTree = (data, orgType) => {
    //orgType --> 要显示的orgType
    let sNodes = []
    let r = []
    let tmpMap = [];

    if (orgType) {
        orgType = orgType + ''
    }

    for (let x = 0; x < data.length; x++) {
        if (orgType) {
            if (orgType.indexOf(data[x].orgType) > -1) {
                if (data[x].lower != undefined) {
                    var obj = {
                        'label': data[x].name + ' ' + '(' + data[x].lower + ')',
                        // 'id': data[x].id,
                        // 'pId': data[x].pId,
                        // 'orgType': data[x].orgType,
                        // 'other': data[x].other,
                        // 'orgCode': data[x].orgCode,
                        // 'user': data[x].user,
                        // 'districtType': data[x].districtType
                    }
                } else {
                    var obj = {
                        'label': data[x].name,
                        // 'id': data[x].id,
                        // 'pId': data[x].pId,
                        // 'orgType': data[x].orgType,
                        // 'other': data[x].other,
                        // 'orgCode': data[x].orgCode,
                        // 'user': data[x].user,
                        // 'districtType': data[x].districtType
                    }
                }
            } else {
                continue
            }
        } else {
            if (data[x].lower != undefined) {
                var obj = {
                    'label': data[x].name + ' ' + '(' + data[x].lower + ')',
                    'code': data[x].code,
                    'lower': data[x].lower,
                }
            } else {


                var obj = {
                    'label': data[x].name,
                    'code': data[x].code,
                    'lower': data[x].lower,
                }
            }
        }
        sNodes.push(obj)
    }

    for (let i = 0, l = sNodes.length; i < l; i++) {
        tmpMap[sNodes[i]['id']] = sNodes[i];
    }
    for (let i = 0, l = sNodes.length; i < l; i++) {
        if (tmpMap[sNodes[i]['pId']] && sNodes[i]['id'] != sNodes[i]['pId']) {
            if (!tmpMap[sNodes[i]['pId']]['children'])
                tmpMap[sNodes[i]['pId']]['children'] = [];
            tmpMap[sNodes[i]['pId']]['children'].push(sNodes[i]);
        } else {
            r.push(sNodes[i]);
        }
    }

    console.log(r)
    console.log(sNodes)
    return r

}

export const getObjType = obj => {
    var toString = Object.prototype.toString;
    var map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    };
    if (obj instanceof Element) {
        return 'element';
    }
    return map[toString.call(obj)];
};
/**
 * 对象深拷贝
 */
 export const deepClone = data => {
    var type = getObjType(data);
    var obj;
    if (type === 'array') {
        obj = [];
    } else if (type === 'object') {
        obj = {};
    } else {
        //不再具有下一层次
        return data;
    }
    if (type === 'array') {
        for (var i = 0, len = data.length; i < len; i++) {
            obj.push(deepClone(data[i]));
        }
    } else if (type === 'object') {
        for (var key in data) {
            obj[key] = deepClone(data[key]);
        }
    }
    return obj;
};

/**
 * 深度递归搜索
 * @param {Array} arr 你要搜索的数组
 * @param {Function} condition 回调函数，必须返回谓词，判断是否找到了。会传入(item, index, level)三个参数
 * @param {String} children 子数组的key
 */
 export const  deepFind = (arr, condition, children) => {
    // 即将返回的数组
    let main = []
    // 用try方案方便直接中止所有递归的程序
    try {
        // 开始轮询
        (function poll(arr, level) {
            // 如果传入非数组
            if (!Array.isArray(arr)) return

            // 遍历数组
            for (let i = 0; i < arr.length; i++) {
                // 获取当前项
                const item = arr[i]

                // 先占位预设值
                main[level] = item

                // 检验是否已经找到了
                const isFind = condition && condition(item, i, level) || false

                // 如果已经找到了
                if (isFind) {
                    // 直接抛出错误中断所有轮询
                    throw Error

                // 如果存在children，那么深入递归
                } else if (children && item[children] && item[children].length) {
                    poll(item[children], level + 1)

                // 如果是最后一个且没有找到值，那么通过修改数组长度来删除当前项
                } else if (i === arr.length - 1) {
                   // 删除占位预设值
                   main.length = main.length - 1
                }
            }
        })(arr, 0)
    // 使用try/catch是为了中止所有轮询中的任务
    } catch (err) {}
    // 返回最终数组
    return main
}

export const debounce = (func, delay) => {
    let timer

    return function (...args) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
}
// 判断访问地址是否为手机端
export const isMobile = () => {
  return window.navigator.userAgent.match(
    /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  );
}
