import { getFormatDateMonth, getFormatDate } from "@utils/dateUtil.js";

export default {
  methods: {
    drawLine() {
      let bar_dv = document.getElementsByClassName('efficiency-charts');
      let head_portraits = document.getElementsByClassName('head-portraits');
      for (var i = 0; i < this.loadProject.length; i++) {
        //从tempUserList中获取linechart,将其转化为arrExamine和upLoadNum和checkNum
        const arrayExamine = [];
        const uploadNum = [];
        const checkNum = [];
        const uploadColor = '';
        // console.log("this.tempUserList[i].lineChart", this.tempUserList[i].lineChart)
        //debugger;
        if (this.tempUserList[i].lineChart == undefined) {
          return 0;
        }
        this.tempUserList[i].lineChart.forEach((chart) => {
          //秒级时间戳转为毫秒级
          const startTimeStamp = chart.stat_time * 1000;
          arrayExamine.push(getFormatDateMonth(startTimeStamp));
          uploadNum.push(chart.upload);
          checkNum.push(chart.check);
        });
        //折线图
        let divId = document.getElementById(this.loadProject[i].id);
        // console.log("divId",divId)
        let myChart = this.$echarts.init(divId);
        // console.log("echars",myChart)
        // 基于准备好的dom，初始化echarts实例
        //   let myChart = this.$echarts.init(document.getElementById('main'))
        // 绘制图表
        var headOption = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: 0,
            data: ['评论', '分享'],
          },
          xAxis: {
            position: 'top',
            boundaryGap: false,
            type: 'category',
            data: arrayExamine,
            axisLine: {
              onZero: false,
              show: false,
            },
            axisTick: {
              //y轴刻度线
              show: true,
              // lineStyle:{
              //   type: 'dotted'
              // },
              lineStyle: {
                type: 'dotted',
                color: '##FFE5E5',
                width: 8,
                opacity: 0.3,
                borderRadio: '50%',
              },
            },

            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
              show: true,
            },
          },
          yAxis: {
            show: false,
            type: 'value',
          },
          series: [
            {
              //这个数据也需要改成真实数据
              data: uploadNum,
              top: 0,
              type: 'line',
              name: '上传文件总数',
              smooth: true,
              itemStyle: {
                normal: {
                  color: '#62CFE3', //小圆点颜色
                  lineStyle: {
                    color: '#62CFE3',
                  },
                },
              },
            },
            {
              data: checkNum,
              top: 0,
              type: 'line',
              name: '通过审核文件数',
              smooth: true,
              itemStyle: {
                normal: {
                  color: this.tempUserList[i].color,
                  lineStyle: {
                    color: this.tempUserList[i].color,
                  },
                },
              },
            },
          ],
        };
        myChart.setOption(headOption);

        //头像图
        let headDiv = document.getElementById(i);
        let headDivMyChart = this.$echarts.init(headDiv);
        // console.log("echars",headDivMyChart)
        // 基于准备好的dom，初始化echarts实例
        //   let myChart = this.$echarts.init(document.getElementById('main'))
        // 绘制图表
        var option = {
          graphic: {
            // 这个属性可以在饼图内部填充图片,文字等
            radius: ['60%', '50%'],
            elements: [
              {
                type: 'image', //需要填充图片,配置image,如果不需要图片可以配置其他的, text, circle, sector, ring, polygon, polyline, rect, line, bezierCurve, arc, group,
                radius: ['60%', '50%'],
                style: {
                  image: this.tempUserList[i].avatar, //这里添加图片地址
                  width: 33,
                  height: 33,
                  radius: ['60%', '50%'],
                },
                left: 'center', //
                top: 'middle', //配置图片居中
              },
            ],
          },
          series: [
            {
              type: 'pie',
              radius: ['36%', '55%'],
              center: ['50%', '50%'], //距离左右，上下距离的百分比
              avoidLabelOverlap: false,
              hoverAnimation: false,
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: this.tempUserList[i].checkImg,
                  itemStyle: { color: this.tempUserList[i].color },
                },
                //未通过是总数减通过数  x = 100
                {
                  value:
                    this.tempUserList[i].uploadImg == 0
                      ? 1
                      : this.tempUserList[i].uploadImg - this.tempUserList[i].checkImg,
                  itemStyle: { color: '#EFF2FB' },
                },
              ],
              force: {
                layoutAnimation: false,
              },
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                },
                // normal: {
                // color: function (params) {
                //   //自定义颜色
                //   // #3468FE'
                //   var colorList = [
                //      "#3468FE",
                //   // this.tempUserList[i].color,
                //     "#EFF2FB",
                //   ];
                //   return colorList[params.dataIndex];
                // },
                // },
              },
            },
          ],
        };
        headDivMyChart.setOption(option);

        // headDivMyChart.on('onclik', function(params) {
        //   window.open('https://www.baidu.com/s?wd=' + encodeURIComponent(params.name));
        // });

      }
      return 1;
    },
  },


};
